@import "../../scss/variables";

.loadout {
	background-color: $themeMono1;
	border: $frameBorder;
	margin: 0 auto;
	padding: $margin1;
	width: 90%;

	& + .loadout {
		margin-top: $margin1;
	}

	h1 {
		font-size: 2rem;
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin: $margin2 0;

		li + li {
			margin-left: 0.75rem;
		}
	}
}

@media screen and (min-width: 640px) {
	.loadout {
		padding: $margin2;
		h1 {
			font-size: 3rem;
		}

		ul {
			margin-bottom: $margin1;
		}

		button {
			margin-left: auto;

			& + a {
				margin-left: 0.75rem;
			}
		}

		a + a {
			margin-left: 0.75rem;
		}
	}
}
