.newsItem {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h4, p {
    margin:0;
  }

  .header {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.4rem;

    h3 {
      font-size:1.4rem;
    }

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      color: white;
    }
  }
}