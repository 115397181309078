@import "../../scss/variables";

svg {
	fill: transparent;

	.bg {
		fill: none;
	}

	.target {
		color: $negative;
	}
}
