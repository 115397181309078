@import "../../../scss/variables";

form {
	background-color: $themeMono1;
	border: $frameBorder;
	padding: $margin1;
	position: relative;
	width: 100%;

	h1 {
		font-size: 6rem;
		margin-bottom: $margin2;
		text-align: center;

		sup {
			display: inline-block;
			font-size: 1.5rem;
			transform: translateY(-1rem);
		}
	}

	.budget {
		font-size: 1.5rem;
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
	}
}

@media screen and (min-width: 640px) {
	form {
		padding: $margin2;
		min-width: 58rem;
		width: 45%;

		h1 {
			font-size: 8rem;

			sup {
				font-size: 2.5rem;
			}
		}

		.budget {
			font-size: 2rem;
			top: 3rem;
			right: 3rem;
		}
	}
}
