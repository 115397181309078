@import "./variables.scss";

h1,
h2,
h3 {
	font-weight: 400;
	text-transform: uppercase;
	line-height: 1;
	margin: 0;
}

h1, .h1 {
	font-size: 3rem;
}

h2, .h2 {
	font-size: 2.8rem;
}

h3, .h3 {
	font-size: 2.3rem;
}

main > h1 {
	text-align: center;
	margin-top: $margin2;

	& + h2 {
		font-size: 3.8rem;
		margin-top: $margin2;
		text-align: center;

		& + section {
			margin-top: $margin2;
		}
	}

	& + section {
		margin-top: $margin2;
	}
}

@media print {
	main > h1 {
		display: none;
	}
}

@media screen and (min-width: 640px) {
	h1 {
		font-size: 6rem;
	}

	main > h1 {
		margin-top: $margin4;

		& + h2 {
			margin-top: $margin2;
		}

		& + section {
			margin-top: $margin4;
		}
	}
}

.text-center {
	text-align: center;
}
