@import "../../scss/variables";

.tag {
	font-size: 1.6rem;
	padding: 0.5rem;
	color: $themeMono3;
	display: none;

	&.isVisible {
		display: inline-block;
	}

	&.basic {
		background-color: $basic;
		color: $themePrimary;
	}

	&.intermediate {
		background-color: $intermediate;
	}

	&.advanced {
		background-color: $advanced;
	}

	&.expert {
		background-color: $expert;
	}

	&.legendary {
		background-color: $legendary;
	}
}

@media print {
	.tag {
		display: none;
	}
}
