@import "../../scss/variables";

.card {
	padding: $margin1;
	border: $frameBorder;
	background-color: $themeMono1;

	.double {
		padding: $margin2;
	}
}
