@import "../../scss/variables";

.protocol {
	margin-top: $margin2;
	position: relative;
	min-height: 26.7rem;

	& + .protocol {
		margin-top: $margin1;
	}

	h1 {
		font-size: 2.3rem;
		margin-bottom: $margin1;
		display: flex;
		align-items: center;
		max-width: calc(90% - 1.5rem);

		span {
			margin-left: 1rem;
		}
	}

	.actionPoints {
		position: absolute;
		top: 1rem;
		right: 1.5rem;
		font-size: 2.4rem;
		margin: 0;

		span {
			font-size: 1.6rem;
			text-transform: uppercase;
		}
	}

	p {
		margin: 0 0 0 $margin1;

		& + p {
			margin-top: 0.5rem;
		}
	}

	.footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: $margin4;

		i .bg {
			fill: none;
		}

		svg {
			display: block;
		}

		i + i {
			margin-left: $margin1;
		}

		i + button {
			margin-left: $margin2;
		}
	}
}

@media print {
	.protocol {
		height: 3.5in !important;
		width: 2.5in !important;
		min-width: 0 !important;
		min-height: 0 !important;

		h1 {
			font-size: 2rem;
		}

		.actionPoints {
			font-size: 2.1rem;

			span {
				font-size: 1.2rem;
			}
		}

		p {
			font-size: 1.2rem;
		}

		.footer {
			margin: 0;
			position: absolute;
			bottom: 1rem;
			right: 1.5rem;

			svg {
				height: 3rem;
				width: 3rem;
			}
		}
	}
}
