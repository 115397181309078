@import "../../scss/variables";

.linkCard {
	border: $frameBorder;
	background-color: $themeMono1;
	color: $themePrimary;
	height: 15rem;
	text-decoration: none;
	transition: all 0.25s linear;
	width: 100%;

	&:hover {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		transform: scale(1.02);

		.img {
			transform: scale(1.02);
		}
	}

	img {
		display: block;
		width: auto;
		height: 26rem;
		transform: scale(0.9);
		transition: transform 0.25s linear;

		& + h2 {
			margin-top: $margin1;
		}
	}

	h2 {
		font-size: 3rem;
		text-transform: uppercase;

		& + details {
			margin-top: $margin1;
		}
	}

	details {
		text-align: center;

		& + .buffsDebuffs {
			margin-top: $margin1;
		}
	}

	.buffsDebuffs {
		width: 100%;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			width: 45%;

			li:before {
				margin-right: 0.5rem;
			}

			&.buffs {
				color: $positive;

				li:before {
					content: "+";
				}

				& + .debuffs {
					margin-top: $margin1;
				}
			}

			&.debuffs {
				color: $negative;

				li:before {
					content: "-";
				}
			}

			& + button {
				margin: $margin1 auto 0;
			}
		}
	}

	details[open] > summary {
		margin-bottom: 0.5rem;
	}

	&.sml {
		height: auto;
		padding: $margin1;
	}
	&.gridded {
		height: auto;
		padding: $margin1;
		width: auto;
	}
}

@media screen and (min-width: 640px) {
	.linkCard {
		& + .linkCard {
			margin-top: 0;
		}

		&.sml {
			padding: $margin2;
			width: 30%;
		}

		.buffsDebuffs ul.buffs + .debuffs {
			margin-top: 0;
		}
	}
}
