@import "../../../scss/variables";

.skillsList {
	background-color: $themeMono1;
	max-height: 90vh;
	overflow-y: auto;
	padding: 1rem;
	width: 45%;

	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 1rem;
		background-color: #f0f0f0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #cdcdcd;
	}

	h2 {
		text-align: center;
		margin: 2rem 0 3rem;
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin-top: 0;

		li {
			background-color: $themeMono3;
			border: solid 2px $themePrimary;
			padding: 1rem 3rem;
			text-transform: uppercase;
			height: 10rem;

			.indicator {
				display: flex;
				justify-content: center;
				align-items: center;

				&:before {
					content: '\23FA';
					color: transparent;
				}
			}

			&.level-0 .indicatorWrap .indicator,
			&.level-1 .indicatorWrap .indicator {
				color: $themeMono2;
			}

			&.level-1 .indicatorWrap .indicator:nth-child(-n + 1) {
				background-color: $themeMono2;

				&:before {
					color: $themeMono2;
				}
			}

			&.level-2 .indicatorWrap .indicator {
				color: $intermediate;

				&:nth-child(-n + 2) {
					background-color: $intermediate;

					&:before {
						color: $intermediate;
					}
				}
			}

			&.level-3 .indicatorWrap .indicator {
				color: $advanced;

				&:nth-child(-n + 3) {
					background-color: $advanced;

					&:before {
						color: $advanced;
					}
				}
			}

			&.level-4 .indicatorWrap .indicator {
				color: $expert;

				&:nth-child(-n + 4) {
					background-color: $expert;

					&:before {
						color: $expert;
					}
				}
			}

			&.level-5 .indicatorWrap .indicator {
				color: $legendary;

				&:nth-child(-n + 5) {
					background-color: $legendary;

					&:before {
						color: $legendary;
					}
				}
			}
		}

		li + li {
			margin-top: 1rem;
		}

		.indicatorWrap + .progressIndicator { margin-top: 1rem}
		.progressIndicator {
			display: none;
			.indicator {
				border: solid 2px $themeMono2;
				display: flex;
				justify-content: center;
				height: 2rem;
				width: 2rem;

				& + .indicator {
					margin-left: 1rem;
				}
			}
		}

		.indicatorWrap {
			.indicator {
				align-items: center;
				border-radius: 50%;
				border: solid 2px currentColor;
				display: flex;
				justify-content: center;
				height: 2rem;
				width: 2rem;
	
					&:before {
						content: '\23FA';
						color: transparent;
						font-size: 2.5rem;
						line-height: 2rem;
					}

				& + .indicator {
					margin-left: 1rem;
				}
			}
		}
	}
}

.playsheet {
	.skillsList {
		background-color: transparent;
		max-height: 100%;
		overflow-y: visible;
		width: 100%;

		ul {
			display: flex;
			flex-wrap: wrap;
			gap: 1rem;

			li {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 32%;

				& + li {margin-top:0}

				button {
					display: none;
				}
			}
		}
	}
}

@media print {

	.playsheet {
		font-size: 1.2rem;

		dl {margin:0}
	}
	.skillsList {
    padding: 0;

		h2 {margin-bottom: 1rem;}

		ul {
			margin-bottom: 0;
			
			li {
				width: 2.59in !important;
				height: 6rem;
				position: relative;

				&:after {
					content: '';
					width: 3rem;
					height: 3rem;
					border: solid 2px currentColor;
					position: absolute;
					right: 1.5rem;
					top: 50%;
					transform: translateY(-50%);
				}
	
				.indicators {
					flex-direction: column;
				}
			}
			.progressIndicator .indicator,
			.indicatorWrap .indicator {
				width: 1rem;
				height: 1rem;

				&:before {
					font-size: 1rem;
					line-height: 1rem;
				}
			}
		}
  }
	.progressIndicator {display: flex !important;}
}
