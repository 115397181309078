@import "../../scss/variables";

$amount: 3;

.hex-grid_item {
	position: relative;
	grid-column: 1 / span 3;
	grid-row: calc(var(--counter) + var(--counter)) / span 2;
	height: 0;
	padding-bottom: 90%;
	transition: all 0.25s linear;

	:hover {
		cursor: pointer;
		transform: scale(1.02);
	}

	// Columns
	@for $i from 1 through $amount {
		&:nth-of-type(#{$amount}n + #{$i}) {
			grid-column: #{$i + $i - 1} / span 3;
			@if $i % 2 == 0 {
				grid-row: calc(var(--counter) + var(--counter) - 1) / span 2;
			}
		}
	}

	// Rows
	@for $i from 1 through 20 {
		&:nth-of-type(n + #{$i * $amount + 1}) {
			--counter: #{$i + 1};
		}
	}

	.hex-grid_content {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: $themeMono1;
		clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		h2 {
			font-size: 2rem;
		}
	}
}
