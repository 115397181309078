@import "../../scss/variables";

.originPath_nav {
	width: 25%;

	ul {
		list-style: none;
		text-transform: uppercase;
		padding: 0;

		li {
			font-size: 2rem;
			text-decoration: none;
			color: inherit;

			&.active {
				font-size: 3rem;
			}

			& + li {
				margin-top: $margin1;
			}
		}
	}
}
