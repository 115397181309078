@import "../../../scss/variables";

fieldset {
	border: 1px solid currentColor;
	flex-grow: 1;
	max-width: 100%;
	padding: $margin1;

	& + fieldset {
		margin-top: $margin1;
	}

	legend {
		font-family: inherit;
		text-transform: uppercase;
	}

	label {
		display: none;
	}

	.inputWrap {
		margin-left: 0 !important;

		& + .inputWrap {
			margin-top: $margin1 !important;
		}
	}

	select + select {
		margin-top: $margin2;
	}
}

@media screen and (min-width: 640px) {
	fieldset {
		max-width: calc(50% - 1.5rem);

		& + fieldset {
			margin-left: $margin1;
			margin-top: 0;
		}
	}
}
