@import "../../../scss/variables";

.radio {
	label {
		transition: all 0.25s linear;
		margin-bottom: 0;

		&:hover {
			cursor: pointer;
			color: $themeMono3;

			.bg {
				fill: $themePrimary;
			}

			.target {
				color: #cd3301;
			}
		}
	}
	input {
		display: none;

		&:checked + label {
			color: $themeMono3;

			.bg {
				fill: $themePrimary;
			}

			.target {
				color: #cd3301;
			}
		}
	}
}
