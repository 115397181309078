@import "../../scss/variables";

.newsFeed-wrap {
  height: auto;
  max-height: 100vh;
  overflow-y: auto;

  .newsFeed {
    list-style: none;
    margin: 0;
    padding: 0;
  
    li + li {
      margin-top: $margin1;
    }
  }
}