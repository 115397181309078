@import "../../scss/variables";

.jumbotron {
	background-color: $themeMono1;
	border: $frameBorder;
	margin: 0 auto;
	padding: $margin2;
	width: 90%;

	h2 {
		margin-bottom: $margin2;
	}

	p {
		margin: 0;
	}

	button {
		margin-top: $margin2;
	}
}
