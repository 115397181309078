.loreList {
	padding-left: 0;
}

.loreItem {
	font-weight: bold;
	text-transform: uppercase;

	span {
		font-weight: normal;
		text-transform: none;
		margin-top: 0.5rem;
	}
}
