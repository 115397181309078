@import "../../scss/variables";

footer {
	background-color: $themeMono1;
	box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
	width: 100%;

	.wrap {
		padding: $margin1 $margin2;
		display: flex;
		align-items: center;

		button:first-of-type {
			margin-right: auto;
		}

		a:last-of-type,
		button:last-of-type {
			margin-left: 1.5rem;
		}

		a,
		button {
			min-width: 15.5rem;
		}
	}
}

@media print {
	footer {
		display: none;
	}
}
