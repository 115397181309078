@import "./scss/variables.scss";
@import "./scss/typography";

@font-face {
	font-family: "Coda";
	src: url(./assets/fonts/Coda-Regular.ttf) format("truetype");
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

:focus:not(:focus-visible) {
	outline: none;
}

html {
	box-sizing: inherit;
	font-size: 62.5%;
}

#root,
body {
	min-height: calc(100vh - 15rem);
}

body {
	color: $themePrimary;
	font-family: $fontFamily;
	font-size: 1.6rem;
	padding: 0;
	margin: 13rem 0 0;
	width: 100%;
}

.wrap {
	max-width: 144rem;
	margin: 0 auto;
	padding: $margin1;
	width: 100%;
}

.build-protocols {
	margin-top: $margin2;
	width: 100%;
}

.choose-class {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	grid-gap: 2rem;
}

.m-t-1 {
	margin-top: 1rem;
}

.m-t-2 {
	margin-top: 2rem;
}

.m-t-4 {
	margin-top: 4rem;
}

.m-b-1 {
	margin-bottom: 1rem;
}

.m-b-2 {
	margin-bottom: 2rem;
}

.m-b-3 {
	margin-bottom: 3rem;
}

.p-t-3 {
	padding-top: 3rem!important;
}

.flex {
	display: flex;

	&-wrap {
		flex-direction: row;
	}

	&-col {
		flex-direction: column;
	}

	&-no_wrap {
		flex-wrap: nowrap;
	}

	&.align-center {
		align-items: center;
	}

	&.align-start {
		align-items: flex-start;
	}

	&.justify-center {
		justify-content: center;
	}

	&.space-between {
		justify-content: space-between;
	}

	&.space-around {
		justify-content: space-around;
	}
}

.gap-1 {
	gap: 1rem
}

.gap-2 {
	gap: 2rem;
}

.wrap_m {
	flex-wrap: wrap;
}

.rarity {
	&-intermediate {
		border: solid 0.3rem $intermediate !important;
	}

	&-advanced {
		border: solid 0.3rem $advanced !important;
	}

	&-expert {
		border: solid 0.3rem $expert !important;
	}

	&-legendary {
		border: solid 0.3rem $legendary !important;
	}

	&-error {
		border: solid 0.3rem $negative !important;
	}
}

.bg {
	&-intermediate {
		background-color: $intermediate;
	}

	&-advanced {
		background-color: $advanced;
	}

	&-expert {
		background-color: $expert;
	}

	&-legendary {
		background-color: $legendary;
	}

	&-basic {
		background-color: $themePrimary;
	}
}

.playsheet {
	flex-wrap: wrap;

	.protocol {
		margin-top: $margin1;
		min-width: 49%;
		max-width: 49%;
	}
}

.loreContacts {
	border: $frameBorder;
	padding: 2rem;
	background-color: $themeMono1;
	max-width: 50%;
}

.aoeWrap {
	display: grid;
	gap: 1.5rem;
	grid-template-columns: repeat(4, minmax(5rem, auto));
}

.login {
	margin: 0 auto;
}

@media print {
	body {
		margin-top: 0;
	}

	.pilot.playsheet {
		width: 8in;
		margin-left: auto;
		margin-right: auto;
	}
}

@media screen and (min-width: 640px) {
	body {
		font-size: 1.6rem;
		margin-top: 15rem;
	}

	.wrap {
		padding: $margin2;
	}

	.wrap_m {
		flex-wrap: nowrap;
	}

	.flex {
		flex-direction: row;
		flex-wrap: wrap;

		&-col {
			flex-direction: column;
		}

		&.align-center_d {
			align-items: center;
		}

		&.space-between_d {
			justify-content: space-between;
		}
	}

	.build-protocols {
		margin-top: 0;
		min-width: 58rem;
		width: 45%;
	}

	.aoeWrap {
		grid-template-columns: repeat(8, minmax(5rem, auto));
	}
}

.grid {
	display: grid;

	form {
		width: auto;
		min-width: auto;
	}

	&.three-cols {
		grid-template-columns: 1fr;
	}
}

@media screen and (min-width: 640px) {
	.grid.three-cols {grid-template-columns:  1fr 1fr;}	
}

@media screen and (min-width: 1023px) {
	.grid.three-cols {grid-template-columns: 1fr 1fr 1fr;}
}
