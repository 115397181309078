@import "../../scss/variables";

header {
	background-color: $themeMono1;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	position: fixed;
	top: 0;
	left: 0;
	transition: all 0.3s ease-in-out;
	width: 100%;
	z-index: 10;

	&.scrolled {
		background-color: rgba(255, 255, 255, 0.8);
		box-shadow: none;

		h1 {
			font-size: 2rem;
		}

		svg {
			height: 5rem;
		}
	}

	.wrap {
		padding: 2.5rem $margin1;
	}

	svg {
		display: block;
		height: 7.5rem;
		transition: all 0.3s ease-in-out;
		width: auto;
	}

	h1 {
		font-size: 3rem;
		margin-left: 1rem;
		text-transform: capitalize;
		transition: font-size 0.3s ease-in-out;

		a {
			color: inherit;
			text-decoration: none;

			&:visited,
			&:hover {
				color: inherit;
				cursor: initial;
			}
		}
	}

	.adminLink {
		margin-inline-start: auto;

		& + button {
			margin-inline-start: 1rem;
		}
	}

	button {
		min-width: 9.5rem;
	}
}

@supports (backdrop-filter: blur()) or (-webkit-backdrop-filter: blur()) {
	header {
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		&.scrolled {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}
}

@media print {
	header {
		display: none;
	}
}

@media screen and (min-width: 640px) {
	header {
		.wrap {
			padding: 2.5rem $margin2;
		}

		h1 {
			margin-left: $margin1;
		}

		svg {
			height: 10rem;
			width: auto;
		}

		&.scrolled {
			svg {
				height: 7.5rem;
			}
		}
	}
}
