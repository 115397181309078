@import "../../scss/variables";

.userMessage {
	border: solid 1px currentColor;
	font-size: 1.6rem;
	margin: 0 auto $margin2 auto;
	padding: $margin1;
	width: 100%;

	svg {
		margin-right: $margin1;
	}

	p {
		margin: 0;
	}

	&.error {
		background-color: $negativeAlpha;
		border-color: $negative;
		color: $negative;
	}

	&.success {
		background-color: $positiveAlpha;
		border-color: $positive;
		color: $positive;
	}
}

@media screen and (min-width: 640px) {
	.userMessage {
		width: 45%;
	}
}
