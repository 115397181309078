@import "../../scss/variables";

.interstitalWrap {
	width: 100%;
}
.interstital {
	display: inline-block;
	width: 8rem;
	height: 8rem;

	&:after {
		content: " ";
		display: block;
		width: 6.4rem;
		height: 6.4rem;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid $themePrimary;
		border-color: $themePrimary transparent $themePrimary transparent;
		animation: dual-ring 1.2s linear infinite;
	}

	p {
		font-size: 2rem;
	}
}

@keyframes dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
