@import "../../../scss/variables.scss";

.inputWrap {
	&.half-width {
		width: 100%;
	}

	&.error {
		label {
			color: $negative;

			&:after {
				content: "";
				background: transparent url("../../../assets/errorIcon.svg") 50% 50%
					no-repeat;
				background-size: 1.9rem;
				display: flex;
				height: 2rem;
				margin-left: 0.5rem;
				width: 2rem;
			}
		}
		input {
			border-color: $negative;
		}
	}
	input,
	select {
		border: solid 1px $themePrimary;
		background-color: $themePrimary10;
		color: $themePrimary;
		font-family: inherit;
		font-size: inherit;
		height: 5rem;
		padding: 0 $margin1;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}
	}

	select {
		text-transform: capitalize;
	}

	& + .inputWrap,
	& + .inlineField {
		margin-top: $margin2;
	}
}

@supports (-ms-ime-align: auto) {
	/* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
	input[type="range"] {
		margin: 0;
		/*Edge starts the margin from the thumb, not the track as other browsers do*/
	}
}

@media screen and (min-width: 640px) {
	.inputWrap {
		&.half-width {
			width: 50%;
		}
	}
}
