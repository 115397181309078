@import "../../scss/variables";

.revealing_menu {
	position: relative;
	margin-left: auto;

	&-wrap {
		background: $themeMono3;
		box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
		display: none;
		flex-direction: column;
		padding: $margin1;
		position: absolute;
		right: 0;
		top: 3rem;

		&.open {
			display: flex;
		}

		button,
		.btn {
			margin: 0;

			+ button,
			+ .btn {
				margin-top: 0.75rem;
			}
		}
	}
}

@media screen and (min-width: 640px) {
	.revealing_menu {
		position: static;
		margin-left: 0;

		&-toggle {
			display: none;
		}

		&-wrap {
			background: transparent;
			box-shadow: none;
			display: flex;
			flex-direction: row;
			padding: 0;
			position: static;

			button,
			.btn {
				margin: 0;

				+ button,
				+ .btn {
					margin-top: 0;
					margin-left: 0.75rem;
				}
			}
		}
	}
}
