/* Colours */
$themePrimary: #2d577d;
$themePrimary50: transparentize($themePrimary, 0.5);
$themePrimary10: transparentize($themePrimary, 0.9);

$themeMono1: #e4e4e4;
$themeMono2: #c4c4c4;
$themeMono3: #fff;

$fontFamily: "Coda", "sans-serif";

$margin1: 1.5rem;
$margin2: 3rem;
$margin3: 4.5rem;
$margin4: 6rem;

/* Rarity */
$legendary: #e7b811;
$expert: #560f8e;
$advanced: #6592d6;
$intermediate: #86bd8b;
$basic: $themeMono2;

/* Messaging */
$negative: #7e0404;
$negative75: transparentize($negative, 0.75);
$negativeAlpha: rgba(126, 4, 4, 0.2);
$positive: #047e2e;
$positiveAlpha: rgba(4, 126, 46, 0.2);
$neutral: $themePrimary;

/* Common */
$frameBorder: double 0.3rem $themeMono3;
