.hex-grid_list {
	--amount: 3;
	--counter: 1;
	display: grid;
	list-style-type: none;
	margin: 0;
	padding: 0;
	grid-template-columns: repeat(var(--amount), 1fr, 2fr) 1fr;
	grid-gap: 0.5rem 4.5rem;
	width: 60%;
	max-width: 51.5rem;
}
