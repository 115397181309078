@import "../../../scss/variables";

.inlineField {
	flex-direction: column;
	.inputWrap {
		flex-grow: 1;
	}

	& + .inlineField,
	& + .inputWrap {
		margin-top: $margin2;
	}
}

@media screen and (min-width: 640px) {
	.inlineField {
		flex-direction: row;
		.inputWrap {
			& + .inputWrap {
				margin-top: 0;
				margin-left: $margin1;
			}
		}
	}
}
