@import "../../../scss/variables";

.btn {
	border: solid 1px $themePrimary;
	font-family: $fontFamily;
	font-size: 1.6rem;
	height: 50px;
	line-height: 2.7rem;
	padding: 0 $margin3;
	text-decoration: none;
	text-transform: uppercase;
	transition: background-color 0.25s linear;
	-webkit-appearance: none;

	&:hover {
		cursor: pointer;
	}

	&.primary {
		background-color: $themePrimary;
		color: $themeMono3;
	}

	&.sml {
		height: 3rem;
		padding: 0 0.5rem;
	}

	&.fancy {
		overflow: hidden;

		> span {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 50px;
			transition: transform 0.25s ease-in-out;

			.chevron {
				stroke: #fff;
			}
		}

		&:hover {
			& > span {
				transform: translateY(-50px);

				.chevron {
					animation-name: colorChange;
					animation-iteration-count: infinite;
					animation-duration: 1s;
					animation-fill-mode: both;

					&.one {
						animation-delay: 0.5s;
					}
					&.two {
						animation-delay: 0.25s;
					}
					&.three {
						animation-delay: 0s;
					}
				}
			}
		}
	}

	&.secondary {
		background-color: $themeMono3;
		color: $themePrimary;

		&:hover {
			background-color: $themePrimary10;
		}
	}

	&.negative {
		border-color: $negative;
		color: $negative;

		&:hover {
			background-color: $negative75;
		}
	}

	&.iconOnly {
		background-color: transparent;
		border: 0;
		height: 3rem;
		padding: 0;
		width: 3rem;

		&:active {
			outline: 0;
		}
	}
}

@keyframes colorChange {
	from {
		stroke: $themeMono3;
	}
	to {
		stroke: $themePrimary;
	}
}
