@import "../../scss/variables";

.modalWrap {
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	inset: 0;
	z-index: 10;

	.modal {
		background-color: $themeMono3;
		padding: $margin2;

		.content {
			margin: 1.5rem 0;

			.radioGroup {
				border: solid 1px currentColor;
				margin: 0 1rem 0 0;
				padding: 1rem;

				& + .radioGroup {
					margin: 0 0 0 1rem;
				}

				label + label {
					margin-bottom: 0;
				}

				input {
					margin: 0 1rem 0 0;
				}

				& + .inputWrap {
					margin-top: $margin1;
				}

				&.row {
					label {
						margin: 0;
					}
					label + label {
						margin-left: 1.5rem;
					}
				}
			}
		}

		.footer {
			button + button {
				margin-left: 1rem;
			}
		}
	}

	h1 {
		font-size: 2rem;
	}
}
